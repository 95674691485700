import React from 'react';
import { Link } from 'gatsby';
import ContactBlock from "src/components/ContactBlock";

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import SimpleMap from 'src/components/SimpleMap';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />

    <div className="py-12 ">
      <div className="text-2xl font-black uppercase md:text-3xl text-primary leading-tight">
            Contacteer ons
      </div>
        <p className={"pt-5"}>
            Hebt u nog vragen of wil u een vrijblijvende offerte? Aarzel dan niet om ons te contacteren.
        </p>
      <div className="mt-16">
        <div className="md:grid md:grid-cols-2 md:gap-8">

          <div>
              <form action="https://formspree.io/f/xgepjvyy" method="POST" className="w-full max-w-lg">
                  <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                          <label className="block uppercase tracking-wide text-secondary text-xs font-bold mb-2"
                                 htmlFor="grid-first-name">
                              Voornaam
                          </label>
                          <input
                              className="appearance-none block w-full bg-gray-200 text-secondary border  border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id="grid-first-name" type="text" placeholder="Jan" name={"voornaam"} required/>
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-secondary text-xs font-bold mb-2"
                                 htmlFor="grid-last-name">
                              Achternaam
                          </label>
                          <input
                              className="appearance-none block w-full bg-gray-200 text-secondary border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-last-name" type="text" placeholder="Janssens" name={"achternaam"} required/>
                      </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-secondary text-xs font-bold mb-2"
                                 htmlFor="grid-password">
                              E-mail
                          </label>
                          <input
                              className="appearance-none block w-full bg-gray-200 text-secondary border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="email" type="email" name={"email"} required/>
                      </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-secondary text-xs font-bold mb-2"
                                 htmlFor="grid-phone">
                              Telefoon
                          </label>
                          <input
                              className="appearance-none block w-full bg-gray-200 text-secondary border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="phone" type="text" name={"telefoon"}/>
                      </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-secondary text-xs font-bold mb-2"
                                 htmlFor="grid-password">
                              Bericht
                          </label>
                          <textarea
                              className=" no-resize appearance-none block w-full bg-gray-200 text-secondary border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                              id="message" name={"bericht"} required></textarea>
                      </div>
                  </div>
                  <div className="md:flex md:items-center">
                      <div className="md:w-1/3">
                          <button
                              className="px-4 py-2 mt-2 text-white rounded shadow-sm sm:relative sm:top-4 hover:no-underline bg-secondary hover:bg-grey shadow-md"
                              type="submit">
                              Versturen
                          </button>
                      </div>
                      <div className="md:w-2/3"></div>
                  </div>
              </form>


          </div>
          <div className={"mt-5 md:mt-0"}>
              <ContactBlock></ContactBlock>

            <SimpleMap></SimpleMap>
          </div>
        </div>

      </div>
    </div>
  </Layout>
);

export default ContactPage;
